import axiosIns from '@/libs/axios'

export function getYandexMarketFbsIntegrations({
  project, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin,
}) {
  return axiosIns.get('/integrations/yandex-market-fbs', {
    params: {
      project,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
    },
  })
}

export function getYandexMarketFbsIntegration({ id }) {
  return axiosIns.get(`/integrations/yandex-market-fbs/${id}`)
}

export function deleteYandexMarketFbsIntegration(id) {
  return axiosIns.delete(`/integrations/yandex-market-fbs/${id}`)
}

export function createYandexMarketFbsIntegration({
  project_id, name, client_id, client_secret, campaign, campaign_token, warehouse_id, business_id, include_vat,
  remain_by_price_rules,
  remain_by_margin_rules,
  dimensions_rules,
  use_external_public_id,
}) {
  return axiosIns.post('/integrations/yandex-market-fbs', {
    project_id,
    name,
    client_id,
    client_secret,
    campaign,
    campaign_token,
    warehouse_id,
    business_id,
    include_vat,
    remain_by_price_rules,
    remain_by_margin_rules,
    dimensions_rules,
    use_external_public_id,
  })
}

export function updateYandexMarketFbsIntegration({
  id, name, client_secret, campaign_token, warehouse_id, include_vat,
  remain_by_price_rules,
  remain_by_margin_rules,
  dimensions_rules,
}) {
  return axiosIns.patch(`/integrations/yandex-market-fbs/${id}`, {
    name,
    client_secret,
    campaign_token,
    warehouse_id,
    include_vat,
    remain_by_price_rules,
    remain_by_margin_rules,
    dimensions_rules,
  })
}

export function getYandexMarketFbsIntegrationShipments({
  id, dateFrom, dateTo, statuses, orderIDs,
}) {
  return axiosIns.get(`/integrations/yandex-market-fbs/${id}/shipments`, {
    params: {
      dateFrom,
      dateTo,
      statuses,
      orderIDs,
    },
  })
}

export function getYandexMarketFbsIntegrationShipment({
  id, shipmentID,
}) {
  return axiosIns.get(`/integrations/yandex-market-fbs/${id}/shipments/${shipmentID}`)
}

export function getYandexMarketFbsIntegrationShipmentLabelsInfo({
  id, shipmentID,
}) {
  return axiosIns.get(`/integrations/yandex-market-fbs/${id}/shipments/${shipmentID}/labels`)
}

export function getYandexMarketFbsIntegrationShipmentAct({
  id, shipmentID,
}) {
  return axiosIns.get(`/integrations/yandex-market-fbs/${id}/shipments/${shipmentID}/act`, {
    responseType: 'blob',
  })
}

export function excludeYandexMarketFbsIntegrationOrdersToNextShipment({
  id, shipmentID, orderIDs,
}) {
  return axiosIns.post(`/integrations/yandex-market-fbs/${id}/shipments/${shipmentID}/exclude-orders`, {
    orderIDs,
  })
}

export function receptionYandexMarketFbsIntegrationShipmentTransferAct({ id }) {
  return axiosIns.get(`/integrations/yandex-market-fbs/${id}/shipments/reception-transfer-act`, {
    responseType: 'blob',
  })
}

export function confirmYandexMarketFbsIntegrationShipment({
  id, shipmentID,
}) {
  return axiosIns.get(`/integrations/yandex-market-fbs/${id}/shipments/${shipmentID}/confirm`)
}

export default {
  getYandexMarketFbsIntegrations,
  getYandexMarketFbsIntegration,
  deleteYandexMarketFbsIntegration,
  createYandexMarketFbsIntegration,
  updateYandexMarketFbsIntegration,
  getYandexMarketFbsIntegrationShipments,
  getYandexMarketFbsIntegrationShipment,
  getYandexMarketFbsIntegrationShipmentLabelsInfo,
  getYandexMarketFbsIntegrationShipmentAct,
  receptionYandexMarketFbsIntegrationShipmentTransferAct,
  confirmYandexMarketFbsIntegrationShipment,
}
