import axiosIns from '@/libs/axios'

export function getMerlionYmdbsIntegrations({
  project, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin,
}) {
  return axiosIns.get('/integrations/merlion-ymdbs', {
    params: {
      project,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
    },
  })
}

export function getMerlionYmdbsIntegration({ id }) {
  return axiosIns.get(`/integrations/merlion-ymdbs/${id}`)
}

export function deleteMerlionYmdbsIntegration(id) {
  return axiosIns.delete(`/integrations/merlion-ymdbs/${id}`)
}

export function createMerlionYmdbsIntegration({
  project_id,
  name,
  merlion_client,
  merlion_login,
  merlion_password,
  merlion_counter_agent,
  business_id,
  campaign,
  campaign_token,
  warehouse_id,
  client_id,
  client_secret,
  include_vat,
  daily_order_hour,
  remain_by_price_rules,
  remain_by_margin_rules,
  update_offers_price_hour,
  dimensions_rules,
  use_external_public_id,
  old_shop,
  delivery_type,
  delivery_name,
  delivery_separator_hour,
  delivery_days_before_separator_hour,
  delivery_days_after_separator_hour,
  seller_inn,
}) {
  return axiosIns.post('/integrations/merlion-ymdbs', {
    project_id,
    name,
    client_id,
    client_secret,
    campaign,
    campaign_token,
    warehouse_id,
    business_id,
    include_vat,
    merlion_client,
    merlion_login,
    merlion_password,
    merlion_counter_agent,
    daily_order_hour,
    remain_by_price_rules,
    remain_by_margin_rules,
    update_offers_price_hour,
    dimensions_rules,
    use_external_public_id,
    old_shop,
    delivery_type,
    delivery_name,
    delivery_separator_hour,
    delivery_days_before_separator_hour,
    delivery_days_after_separator_hour,
    seller_inn,
  })
}

export function updateMerlionYmdbsIntegration({
  id,
  name,
  merlion_counter_agent,
  campaign_token,
  warehouse_id,
  client_secret,
  include_vat,
  daily_order_hour,
  remain_by_price_rules,
  remain_by_margin_rules,
  update_offers_price_hour,
  dimensions_rules,
  old_shop,
  delivery_type,
  delivery_name,
  delivery_separator_hour,
  delivery_days_before_separator_hour,
  delivery_days_after_separator_hour,
  seller_inn,
}) {
  return axiosIns.patch(`/integrations/merlion-ymdbs/${id}`, {
    name,
    merlion_counter_agent,
    campaign_token,
    warehouse_id,
    client_secret,
    include_vat,
    daily_order_hour,
    remain_by_price_rules,
    remain_by_margin_rules,
    update_offers_price_hour,
    dimensions_rules,
    old_shop,
    delivery_type,
    delivery_name,
    delivery_separator_hour,
    delivery_days_before_separator_hour,
    delivery_days_after_separator_hour,
    seller_inn,
  })
}

export function getMerlionCounterAgents({
  client, login, password,
}) {
  return axiosIns.post('/guidebook/merlion-counter-agents', {
    client, login, password,
  })
}

export default {
  getMerlionYmdbsIntegrations,
  getMerlionYmdbsIntegration,
  deleteMerlionYmdbsIntegration,
  createMerlionYmdbsIntegration,
  updateMerlionYmdbsIntegration,
  getMerlionCounterAgents,
}
