import axiosIns from '@/libs/axios'

export function getMerlionFreshYmfbsIntegrations({
  project, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin,
}) {
  return axiosIns.get('/integrations/merlion-fresh-ymfbs', {
    params: {
      project,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
    },
  })
}

export function getMerlionFreshYmfbsIntegration({ id }) {
  return axiosIns.get(`/integrations/merlion-fresh-ymfbs/${id}`)
}

export function deleteMerlionFreshYmfbsIntegration(id) {
  return axiosIns.delete(`/integrations/merlion-fresh-ymfbs/${id}`)
}

export function createMerlionFreshYmfbsIntegration({
  project_id,
  name,
  client_id,
  client_secret,
  campaign,
  campaign_token,
  warehouse_id,
  business_id,
  include_vat,
  merlion_client,
  merlion_login,
  merlion_password,
  merlion_counter_agent,
  fresh_login,
  fresh_password,
  fresh_manager_email,
  daily_order_hour,
  daily_email_hour,
  remain_by_price_rules,
  remain_by_margin_rules,
  update_offers_price_hour,
  dimensions_rules,
  use_external_public_id,
}) {
  return axiosIns.post('/integrations/merlion-fresh-ymfbs', {
    project_id,
    name,
    client_id,
    client_secret,
    campaign,
    campaign_token,
    warehouse_id,
    business_id,
    include_vat,
    merlion_client,
    merlion_login,
    merlion_password,
    merlion_counter_agent,
    fresh_login,
    fresh_password,
    fresh_manager_email,
    daily_order_hour,
    daily_email_hour,
    remain_by_price_rules,
    remain_by_margin_rules,
    update_offers_price_hour,
    dimensions_rules,
    use_external_public_id,
  })
}

export function updateMerlionFreshYmfbsIntegration({
  id, name,
  merlion_counter_agent,
  client_secret, campaign_token, warehouse_id, include_vat, daily_order_hour, fresh_manager_email, daily_email_hour,
  remain_by_price_rules,
  remain_by_margin_rules,
  update_offers_price_hour,
  dimensions_rules,
}) {
  return axiosIns.patch(`/integrations/merlion-fresh-ymfbs/${id}`, {
    name,
    merlion_counter_agent,
    client_secret,
    campaign_token,
    warehouse_id,
    include_vat,
    daily_order_hour,
    fresh_manager_email,
    daily_email_hour,
    remain_by_price_rules,
    remain_by_margin_rules,
    update_offers_price_hour,
    dimensions_rules,
  })
}

export function getMerlionFreshYmfbsIntegrationShipments({
  id, dateFrom, dateTo, statuses, orderIDs,
}) {
  return axiosIns.get(`/integrations/merlion-fresh-ymfbs/${id}/shipments`, {
    params: {
      dateFrom,
      dateTo,
      statuses,
      orderIDs,
    },
  })
}

export function getMerlionFreshYmfbsIntegrationShipment({
  id, shipmentID,
}) {
  return axiosIns.get(`/integrations/merlion-fresh-ymfbs/${id}/shipments/${shipmentID}`)
}

export function getMerlionFreshYmfbsIntegrationShipmentLabelsInfo({
  id, shipmentID,
}) {
  return axiosIns.get(`/integrations/merlion-fresh-ymfbs/${id}/shipments/${shipmentID}/labels`)
}

export function getMerlionFreshYmfbsIntegrationShipmentAct({
  id, shipmentID,
}) {
  return axiosIns.get(`/integrations/merlion-fresh-ymfbs/${id}/shipments/${shipmentID}/act`, {
    responseType: 'blob',
  })
}

export function excludeMerlionFreshYmfbsIntegrationOrdersToNextShipment({
  id, shipmentID, orderIDs,
}) {
  return axiosIns.post(`/integrations/merlion-fresh-ymfbs/${id}/shipments/${shipmentID}/exclude-orders`, {
    orderIDs,
  })
}

export function receptionMerlionFreshYmfbsIntegrationShipmentTransferAct({ id }) {
  return axiosIns.get(`/integrations/merlion-fresh-ymfbs/${id}/shipments/reception-transfer-act`, {
    responseType: 'blob',
  })
}

export function confirmMerlionFreshYmfbsIntegrationShipment({
  id, shipmentID,
}) {
  return axiosIns.get(`/integrations/merlion-fresh-ymfbs/${id}/shipments/${shipmentID}/confirm`)
}

export function getMerlionCounterAgents({
  client, login, password,
}) {
  return axiosIns.post('/guidebook/merlion-counter-agents', {
    client, login, password,
  })
}

export default {
  getMerlionFreshYmfbsIntegrations,
  getMerlionFreshYmfbsIntegration,
  deleteMerlionFreshYmfbsIntegration,
  createMerlionFreshYmfbsIntegration,
  updateMerlionFreshYmfbsIntegration,
  getMerlionFreshYmfbsIntegrationShipments,
  getMerlionFreshYmfbsIntegrationShipment,
  getMerlionFreshYmfbsIntegrationShipmentLabelsInfo,
  getMerlionFreshYmfbsIntegrationShipmentAct,
  receptionMerlionFreshYmfbsIntegrationShipmentTransferAct,
  confirmMerlionFreshYmfbsIntegrationShipment,
  getMerlionCounterAgents,
}
