import {
  getCurrentInstance,
  ref,
  computed,
} from 'vue'

import { getYandexMarketFbsIntegrations } from '@/services/main-api/integrations/yandex-market-fbs'
import { getYandexMarketDbsIntegrations } from '@/services/main-api/integrations/yandex-market-dbs'
import { getMerlionFreshYmfbsIntegrations } from '@/services/main-api/integrations/merlion-fresh-ymfbs'
import { getMerlionYmdbsIntegrations } from '@/services/main-api/integrations/merlion-ymdbs'

export const types = [
  {
    label: 'ЯндексМаркет FBS',
    value: 'yandex_market_fbs',
  },
  {
    label: 'ЯндексМаркет DBS',
    value: 'yandex_market_dbs',
  },
  {
    label: 'Merlion ЯндексFBS FreshLogic',
    value: 'merlion_fresh_ymfbs',
  },
  {
    label: 'Merlion ЯндексDBS',
    value: 'merlion_ymdbs',
  },
]

export default function useIntegrations() {
  const instance = getCurrentInstance().proxy

  const yandexMarketFbsItems = ref([])

  const getYandexMarketFbsItems = async () => {
    try {
      const res = await getYandexMarketFbsIntegrations({
        project: instance.$store.getters['workingMode/selected_project_id'],
        perPage: 1000,
      })

      yandexMarketFbsItems.value = res.data.data.map(i => ({
        ...i,
        type: 'yandex_market_fbs',
        humanType: 'ЯндексМаркет FBS',
      }))
    } catch (e) {
      yandexMarketFbsItems.value = []
    }
  }

  const yandexMarketDbsItems = ref([])

  const getYandexMarketDbsItems = async () => {
    try {
      const res = await getYandexMarketDbsIntegrations({
        project: instance.$store.getters['workingMode/selected_project_id'],
        perPage: 1000,
      })

      yandexMarketDbsItems.value = res.data.data.map(i => ({
        ...i,
        type: 'yandex_market_dbs',
        humanType: 'ЯндексМаркет DBS',
      }))
    } catch (e) {
      yandexMarketDbsItems.value = []
    }
  }

  const merlionFreshYmfbsItems = ref([])

  const getMerlionFreshYmfbsItems = async () => {
    try {
      const res = await getMerlionFreshYmfbsIntegrations({
        project: instance.$store.getters['workingMode/selected_project_id'],
        perPage: 1000,
      })

      merlionFreshYmfbsItems.value = res.data.data.map(i => ({
        ...i,
        type: 'merlion_fresh_ymfbs',
        humanType: 'Merlion ЯндексFBS FreshLogic',
      }))
    } catch (e) {
      merlionFreshYmfbsItems.value = []
    }
  }

  const merlionYmDbsItems = ref([])

  const getMerlionYmDbsItems = async () => {
    try {
      const res = await getMerlionYmdbsIntegrations({
        project: instance.$store.getters['workingMode/selected_project_id'],
        perPage: 1000,
      })

      merlionYmDbsItems.value = res.data.data.map(i => ({
        ...i,
        type: 'merlion_ymdbs',
        humanType: 'Merlion ЯндексDBS',
      }))
    } catch (e) {
      merlionYmDbsItems.value = []
    }
  }

  const getItems = async () => {
    await Promise.all([
      getYandexMarketFbsItems(),
      getYandexMarketDbsItems(),
      getMerlionFreshYmfbsItems(),
      getMerlionYmDbsItems(),
    ])
  }

  const items = computed(() => [
    ...yandexMarketFbsItems.value,
    ...yandexMarketDbsItems.value,
    ...merlionFreshYmfbsItems.value,
    ...merlionYmDbsItems.value,
  ])

  return {
    types,
    items,
    getItems,
  }
}
