<script>
import {
  getCurrentInstance, onMounted, ref, reactive, watch, computed,
} from 'vue'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import uniqBy from 'lodash/uniqBy'
import ListCrud from '@/components/crud/ListCrud.vue'
import useCrudList from '@/composables/useCrudList'
import { getProductOffersByProducts, deleteProductOffer, updateProductOffer } from '@/services/main-api/shop/product-offers'
import { getBrands as getBrandsApi } from '@/services/main-api/catalog/products/brands'
import { getCategories as getCategoriesApi } from '@/services/main-api/catalog/products/categories'
import useIntegrations from '@/composables/useIntegrations'
import { productsToCsvForParser } from '@/services/main-api/catalog/products/export'

import {
  BBadge,
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BCol,
  BDropdown,
  BDropdownItem,
  BLink,
  BRow,
  BFormRow,
  BTooltip,
  BCardHeader,
  BFormRadioGroup, BFormRadio,
  BFormCheckbox,
  BProgress,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useActionsWithManyItems from '../composables/useActionsWithManyItems'

export default {
  name: 'ListView',
  components: {
    BFormRadio,
    BFormRadioGroup,
    BCol,
    BRow,
    BFormRow,
    BCardBody,
    BCard,
    BDropdown,
    BButton,
    BButtonGroup,
    BTooltip,
    BDropdownItem,
    BBadge,
    BLink,
    BCardHeader,
    BFormCheckbox,
    ListCrud,
    vSelect,
    BProgress,
  },
  setup() {
    const instance = getCurrentInstance().proxy

    const filter = reactive({
      brand: null,
      category: null,
    })

    // BEGIN Brands
    const brands = ref([])

    async function getBrands() {
      try {
        const res = await getBrandsApi({
          perPage: 9999,
          orderBy: 'name',
          sortedBy: 'asc',
        })

        brands.value = res.data.data
      } catch (e) {
        brands.value = []
      }
    }
    // END Brands

    // BEGIN Categories
    const categories = ref([])

    async function getCategories() {
      try {
        const res = await getCategoriesApi({
          project: instance.$store.getters['workingMode/selected_project_id'],
          perPage: 10000,
          sortedBy: 'asc',
          orderBy: 'name',
          includes: 'children',
          mode: 'by_product_offers',
        })

        categories.value = res.data.data.filter(c => !c.children || !c.children.length)
      } catch (e) {
        categories.value = []
      }
    }

    // END Categories

    // BEGIN Integrations
    const { getItems: getIntegrations, items: integrations } = useIntegrations()
    // END Integrations

    const getItems = async () => {
      let search = null
      let relationSearch = null

      const searchFields = [
      ]

      const relationSearchFields = [
        // `product_category_id:${category}`,
      ]

      if (instance.searchQuery) {
        if (instance.searchQuery.replace(/\D/g, '').length === instance.searchQuery.length) {
          searchFields.push(`id:${instance.searchQuery}`)
          searchFields.push(`external_public_id:${instance.searchQuery}`)
        } else {
          relationSearchFields.push(`name:${instance.searchQuery}`)
        }
      }

      if (filter.brand) {
        relationSearchFields.push(`product_brand_id:${filter.brand}`)
      }

      if (filter.category) {
        relationSearchFields.push(`product_category_id:${filter.category}`)
      }

      if (searchFields.length) {
        search = searchFields.join(';')
      }

      if (relationSearchFields.length) {
        relationSearch = relationSearchFields.join(';')
      }

      try {
        const res = await getProductOffersByProducts({
          project: instance.$store.getters['workingMode/selected_project_id'],
          perPage: instance.perPage,
          sortedBy: instance.isSortDirDesc ? 'desc' : 'asc',
          orderBy: instance.sortBy,
          page: instance.currentPage,
          search,
          relationSearch,
          searchJoin: 'or',
          relationSearchJoin: 'and',
          includes: 'product_warehouse_remains',
          mode: 'by_offer',
        })

        instance.items = res.data.data
        instance.currentPage = res.data.meta.current_page
        instance.from = res.data.meta.from
        instance.to = res.data.meta.to
        instance.totalItems = res.data.meta.total
      } catch (e) {
        instance.items = []
      }
    }

    const {
      loading,

      perPageOptions,
      perPage,
      from,
      to,
      currentPage,
      totalItems,

      sortBy,
      searchQuery,
      isSortDirDesc,
      tableColumns,

      items,
      deleteItem,
    } = useCrudList({
      sortBy: 'id',
      isSortDirDesc: true,
      tableColumns: [
        { key: 'checkbox', label: '' },
        { key: 'id', label: 'Предложение' },
        { key: 'brand', label: 'Бренд' },
        { key: 'price', label: 'Цена' },
        { key: 'is_active', label: 'Активность' },
        { key: 'product_warehouse_remains', label: 'Остатки / Резерв' },
        { key: 'integrations', label: 'Интеграции' },
        {
          key: 'actions', label: 'Действия', tdClass: 'text-right', thClass: 'text-right',
        },
      ],
      perPageOptions: [50, 100, 500],
      perPage: 50,
      getItems,
      getMoreData: async () => {
        await Promise.all([
          getBrands(),
          getCategories(),
          getIntegrations(),
        ])
      },
      deleteEntity: item => deleteProductOffer(item.id),
      getQuestionTextBeforeDeletion: item => `Предложение ${item.product.name} будет удалено`,
      getTextAfterDeletion: () => 'Предложение было удалено.',
    })

    onMounted(() => {
      if (!instance.$store.getters['workingMode/mode']) {
        instance.$router.push({ name: 'dashboard' })
      }
    })

    const getWarehouseRemainsTotalByOffer = offerObject => {
      const warehouseRemains = get(offerObject, 'product_warehouse_remains', [])
      const count = warehouseRemains.reduce((sum, item) => sum + item.total, 0)

      return count
    }

    const getWarehouseRemainsReserveByOffer = offerObject => {
      const warehouseRemains = get(offerObject, 'product_warehouse_remains', [])
      const count = warehouseRemains.reduce((sum, item) => sum + item.reserve, 0)

      return count
    }

    const activeBasketBtn = offerObject => (getWarehouseRemainsTotalByOffer(offerObject) - getWarehouseRemainsReserveByOffer(offerObject)) > 0 && get(offerObject, 'is_active', false)

    // BEGIN Sort
    const sortFields = [
      { label: 'ID', value: 'id' },
      { label: 'Названию', value: 'product.name' },
      { label: 'Активности', value: 'is_active' },
      { label: 'Цене', value: 'price' },
      { label: 'Создан', value: 'created_at' },
      { label: 'Изменен', value: 'updated_at' },
    ]
    // END Sort

    // BEGIN Множественные действия
    // function sleep(ms) {
    //   return new Promise(resolve => setTimeout(resolve, ms))
    // }

    async function onItemAction(item) {
      await updateProductOffer({
        id: item.id,
        is_active: true,
      })
    }

    async function offItemAction(item) {
      await updateProductOffer({
        id: item.id,
        is_active: false,
      })
    }

    async function getData() {
      loading.value = true
      await getItems()
      loading.value = false
    }

    async function editIntegration(item, integration, enabled) {
      const type = integration.type.split('-').join('_')
      const itemIntegrations = item[type]

      const index = itemIntegrations.findIndex(i => i.id === integration.id)

      if (index !== -1) {
        itemIntegrations[index].enabled = enabled
      } else {
        itemIntegrations.push({
          id: integration.id,
          enabled,
        })
      }

      const params = {
        id: item.id,
      }

      params[type] = itemIntegrations

      await updateProductOffer(params)
    }

    async function exportForParser(data) {
      let eItems = data.filter(i => !!i).map(i => ({
        product_id: get(i, 'product.id'),
        category_id: get(i, 'product.product_category_id'),
      }))

      eItems = uniqBy(eItems, 'product_id')
      const groupItems = groupBy(eItems, 'category_id')

      // eslint-disable-next-line no-restricted-syntax
      for (const groupItem of Object.values(groupItems)) {
        // eslint-disable-next-line no-await-in-loop
        const res = await productsToCsvForParser({
          ids: groupItem.map(i => i.product_id),
        })

        const url = window.URL.createObjectURL(res.data)

        const fakeLink = document.createElement('a')
        fakeLink.href = url
        // fakeLink.setAttribute('target', '_blank')
        fakeLink.setAttribute('download', `products - ${categories.value.find(i => i.id === groupItem[0].category_id)?.name}.csv`)
        fakeLink.click()
      }
    }

    const actionListRaw = computed(() => [
      {
        label: 'Включить',
        action: onItemAction,
      },
      {
        label: 'Выключить',
        action: offItemAction,
      },
      {
        label: 'Удалить',
        action: deleteItem,
      },
      ...integrations.value.map(i => ({
        label: `Off ${i.name}`,
        action: async item => {
          await editIntegration(item, i, false)
        },
      })),
      ...integrations.value.map(i => ({
        label: `On ${i.name}`,
        action: async item => {
          await editIntegration(item, i, true)
        },
      })),
      {
        label: 'Export for Parser',
        action: exportForParser,
        isMultiple: true,
      },
    ])

    const {
      selectedIds,
      isSelectedAll,
      isIndeterminate,
      toggleAll,
      clearAll,
      actionList,
      selectedActionIndex,
      runAction,
      statusAction,
      clearStatusAction,
    } = useActionsWithManyItems({
      items,
      actionList: actionListRaw,
      runAfterAction: async () => {
        await getData()
      },
    })
    // END Множественные действия

    watch(() => filter, async () => {
      await getData()
    }, { deep: true })

    // todo-gav Подумать и вывести ли в useActionsWithManyItems привязавшись к изменению items
    watch(currentPage, () => {
      selectedIds.value = []
    })

    function getItegrationByItem(item) {
      const res = [
        ...get(item, 'yandex_market_fbs', []).map(i => ({
          ...i,
          type: 'yandex_market_fbs',
        })),
        ...get(item, 'yandex_market_dbs', []).map(i => ({
          ...i,
          type: 'yandex_market_dbs',
        })),
        ...get(item, 'merlion_fresh_ymfbs', []).map(i => ({
          ...i,
          type: 'merlion_fresh_ymfbs',
        })),
        ...get(item, 'merlion_ymdbs', []).map(i => ({
          ...i,
          type: 'merlion_ymdbs',
        })),
      ]
        .filter(i => i.enabled)
        .map(i => {
          const integration = integrations.value.find(j => j.id === i.id)
          return {
            ...i,
            name: get(integration, 'name', ''),
          }
        })

      return res
    }

    return {
      loading,

      perPageOptions,
      perPage,
      from,
      to,
      currentPage,
      totalItems,

      sortBy,
      searchQuery,
      isSortDirDesc,
      tableColumns,

      items,
      deleteItem,

      getWarehouseRemainsTotalByOffer,
      getWarehouseRemainsReserveByOffer,
      activeBasketBtn,

      // Sort
      sortFields,

      filter,

      // Brands
      brands,

      // Categories
      categories,

      get,

      // Множественные действия
      selectedIds,
      isIndeterminate,
      isSelectedAll,
      toggleAll,
      clearAll,
      actionList,
      selectedActionIndex,
      runAction,
      statusAction,
      clearStatusAction,

      getItegrationByItem,
    }
  },
}
</script>

<template>
  <div class="offers-page">
    <!--    <b-card no-body>-->
    <!--      <b-card-header class="pb-50">-->
    <!--        <h5>-->
    <!--          Filters-->
    <!--        </h5>-->
    <!--      </b-card-header>-->
    <!--      <b-card-body />-->
    <!--    </b-card>-->
    <list-crud
      :loading="loading"
      :per-page.sync="perPage"
      :per-page-options="perPageOptions"
      :search-query.sync="searchQuery"
      :items="items"
      :table-columns="tableColumns"
      :sort-by.sync="sortBy"
      :is-sort-dir-desc.sync="isSortDirDesc"
      :current-page.sync="currentPage"
      :from="from"
      :to="to"
      :total-items="totalItems"
      :hide-create-btn="!$store.getters['workingMode/checkPermission']('manage_product_offers')"
      @click-btn-create="$router.push({ name: 'global-catalog' })"
    >
      <template #header>
        <b-row class="mb-1">
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >

            <label>Бренд</label>
            <v-select
              v-model="filter.brand"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="true"
              :options="brands"
              class="w-100"
              :reduce="val => val.id"
              label="name"
            />

          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Категория</label>
            <v-select
              v-model="filter.category"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="true"
              :options="categories"
              class="w-100"
              :reduce="val => val.id"
              label="name"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <div class="">
              <label>Сортировать по</label>
              <div class="offers-page__sort">
                <v-select
                  v-model="sortBy"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sortFields"
                  :clearable="false"
                  class="offers-page__sort__select d-inline-block mr-50"
                  label="label"
                  :reduce="option => option.value"
                />
                <b-button
                  variant="outline-secondary"
                  class="btn-icon offers-page__sort__sort-dir"
                  :class="{'offers-page__sort__sort-dir_active': isSortDirDesc}"
                  @click="isSortDirDesc = !isSortDirDesc"
                >
                  <feather-icon icon="BarChartIcon" />
                </b-button>
              </div>
            </div>
          </b-col>

        </b-row>

      </template>

      <template #header-bottom>
        <div
          v-if="selectedIds.length && !statusAction.loading"
          class="mt-1"
        >
          <label class="d-block">Выбрано: {{ selectedIds.length }} из {{ items.length }}</label>
          <b-form-row>
            <b-col cols="auto">
              <b-dropdown
                variant="outline-primary"
                left
                split
                text="Split Menu"
                size="sm"
                @click="runAction"
              >
                <template #button-content>
                  {{ selectedActionIndex !== null ? actionList[selectedActionIndex].label : 'Action' }}
                </template>
                <b-dropdown-item
                  v-for="(actionItem, actionIndex) in actionList"
                  :key="'ai' + actionItem.label"
                  @click="selectedActionIndex = actionIndex"
                >
                  {{ actionItem.label }}
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
            <b-col cols="auto">
              <b-button
                size="sm"
                variant="outline-danger"
                class="btn-icon"
                @click="clearAll"
              >
                <feather-icon
                  icon="XIcon"
                />
              </b-button>
            </b-col>
          </b-form-row>

        </div>
        <div
          v-if="statusAction.loading || statusAction.successCount || statusAction.errorCount"
          class="mt-1"
        >
          <b-progress
            v-if="statusAction.loading"
            :value="statusAction.successCount + statusAction.errorCount"
            :max="selectedIds.length"
            show-progress
            animated
            class="mb-1"
          />
          <div class="small">
            Выполнено действие: <strong>{{ statusAction.action }}</strong>
            <div class="text-success">
              Удачно: {{ statusAction.successCount }} из {{ statusAction.total }}
            </div>
            <div class="text-danger">
              Неудачно: {{ statusAction.errorCount }} из {{ statusAction.total }}
            </div>
          </div>
          <div class="" />
        </div>
      </template>

      <template #head(checkbox)>
        <b-form-checkbox
          :indeterminate="isIndeterminate"
          :value="true"
          :checked="isSelectedAll"
          class="m-0 p-0"
          @change="toggleAll"
        />
      </template>

      <template #cell(checkbox)="data">
        <b-form-checkbox
          v-model="selectedIds"
          :value="data.item.id"
          class="m-0 p-0"
        />
      </template>

      <template #cell(id)="data">
        <!--        <pre class="small">{{ data.item }}</pre>-->
        <div class="small">
          ID: {{ data.item.id }}
          <template v-if="data.item.external_public_id">
            • Внешний ID: {{ data.item.external_public_id }}
          </template>
          <template v-if="data.item.product_id">
            • Product ID: {{ data.item.product_id }}
          </template>
          • {{ categories.find(b => b.id === data.item.product.product_category_id)?.name }}
        </div>
        <div>
          <b-link :to="{ name: 'offers.show', params: { id: data.item.id } }">
            {{ get(data.item, 'product.name') }}
          </b-link>
        </div>

      </template>

      <template #cell(brand)="data">

        {{ brands.find(b => b.id === data.item.product.product_brand_id)?.name }}

      </template>

      <template #cell(price)="data">
        <div
          class="small"
          style="text-decoration: line-through;"
        >
          {{ Number(get(data.item, 'sale_price', '')) ? Number(get(data.item, 'price', '')) : '' }}
        </div>
        <div class="small">
          <strong>{{ Number(get(data.item, 'sale_price', '')) || Number(get(data.item, 'price', '')) }}</strong>
        </div>
      </template>

      <template #cell(product_warehouse_remains)="data">
        <small>
          {{ getWarehouseRemainsTotalByOffer(data.item) }} /
          {{ getWarehouseRemainsReserveByOffer(data.item) }}
        </small>
      </template>

      <template #cell(is_active)="data">
        <b-badge
          pill
          variant=""
          class="text-capitalize"
          :variant="data.item.is_active ? 'light-success' : 'light-secondary'"
        >
          {{ data.item.is_active ? 'Вкл' : 'Выкл' }}
        </b-badge>
      </template>

      <template #cell(integrations)="data">
        <div
          v-if="!getItegrationByItem(data.item).length"
          class="small"
        >
          -
        </div>
        <div
          v-for="integration in getItegrationByItem(data.item)"
          :key="'oi' + data.item.id + integration.id"
          class="small"
        >
          {{ integration.name }}
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <b-button
            v-if="activeBasketBtn(data.item)"
            :id="`offer-row-${data.item.id}-basket-button`"
            variant="link"
            class="btn-icon"
            @click="$store.dispatch('basket/showModal', data.item)"
          >
            <feather-icon
              icon="ShoppingCartIcon"
            />
          </b-button>

          <b-tooltip
            v-if="activeBasketBtn(data.item)"
            title="Добавить в корзину"
            class="cursor-pointer"
            :target="`offer-row-${data.item.id}-basket-button`"
          />

          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'offers.show', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Посмотреть</span>
            </b-dropdown-item>

            <template v-if="$store.getters['workingMode/checkPermission']('manage_product_offers')">
              <b-dropdown-item :to="{ name: 'offers.edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Изменить</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon
                  icon="TrashIcon"
                  @click="deleteItem(data.item)"
                />
                <span class="align-middle ml-50">Удалить</span>
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </div>

      </template>
    </list-crud>
  </div>
</template>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss" scoped>
.offers-page{
  &__sort{
    display: flex;

    &__sort-dir{
      svg{
        transform: rotate(90deg);
      }
      &_active{
        svg{
          transform: rotate(90deg) rotateZ(180deg);
        }
      }
    }

    &__select{
      // width: 150px;
      flex: 1;
    }
  }
}
</style>
