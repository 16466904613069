import axiosIns from '@/libs/axios'

export function getBrand(id) {
  return axiosIns.get(`/catalog/products/brands/${id}`)
}

export function getBrands({
  perPage, sortedBy, orderBy, page, search, searchFields, searchJoin,
}) {
  return axiosIns.get('/catalog/products/brands', {
    params: {
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
    },
  })
}

export default {
  getBrand,
  getBrands,
}
