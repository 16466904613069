import axiosIns from '@/libs/axios'

export function getYandexMarketDbsIntegrations({
  project, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin,
}) {
  return axiosIns.get('/integrations/yandex-market-dbs', {
    params: {
      project,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
    },
  })
}

export function getYandexMarketDbsIntegration({ id }) {
  return axiosIns.get(`/integrations/yandex-market-dbs/${id}`)
}

export function deleteYandexMarketDbsIntegration(id) {
  return axiosIns.delete(`/integrations/yandex-market-dbs/${id}`)
}

export function createYandexMarketDbsIntegration({
  project_id, name, client_id, client_secret, campaign, campaign_token, warehouse_id, business_id, include_vat,
  remain_by_price_rules,
  remain_by_margin_rules,
  dimensions_rules,
  use_external_public_id,
  delivery_type,
  delivery_name,
  delivery_separator_hour,
  delivery_days_before_separator_hour,
  delivery_days_after_separator_hour,
  seller_inn,
}) {
  return axiosIns.post('/integrations/yandex-market-dbs', {
    project_id,
    name,
    client_id,
    client_secret,
    campaign,
    campaign_token,
    warehouse_id,
    business_id,
    include_vat,
    remain_by_price_rules,
    remain_by_margin_rules,
    dimensions_rules,
    use_external_public_id,
    delivery_type,
    delivery_name,
    delivery_separator_hour,
    delivery_days_before_separator_hour,
    delivery_days_after_separator_hour,
    seller_inn,
  })
}

export function updateYandexMarketDbsIntegration({
  id, name, client_secret, campaign_token, warehouse_id, include_vat,
  remain_by_price_rules,
  remain_by_margin_rules,
  dimensions_rules,
  delivery_type,
  delivery_name,
  delivery_separator_hour,
  delivery_days_before_separator_hour,
  delivery_days_after_separator_hour,
  seller_inn,
}) {
  return axiosIns.patch(`/integrations/yandex-market-dbs/${id}`, {
    name,
    client_secret,
    campaign_token,
    warehouse_id,
    include_vat,
    remain_by_price_rules,
    remain_by_margin_rules,
    dimensions_rules,
    delivery_type,
    delivery_name,
    delivery_separator_hour,
    delivery_days_before_separator_hour,
    delivery_days_after_separator_hour,
    seller_inn,
  })
}
