import {
  computed, getCurrentInstance, ref, reactive,
} from 'vue'

export default function useActionsWithManyItems({
  items, // реактивный список сущности
  actionList = ref([]), // todo-gav сделать что бы можно было передать как реактивный так и нет
  runAfterAction = async () => {},
}) {
  const instance = getCurrentInstance().proxy

  // Массивы выбранных id
  const selectedIds = ref([])
  // Определяет выбраны ли все
  const isSelectedAll = computed(() => !!(selectedIds.value.length && selectedIds.value.length === items.value.length))
  // Выбрана часть
  const isIndeterminate = computed(() => !!(selectedIds.value.length && selectedIds.value.length !== items.value.length))

  function toggleAll() {
    if (items.value.length === 0) {
      selectedIds.value = []
    } else if (items.value.length === selectedIds.value.length) {
      selectedIds.value = []
    } else {
      selectedIds.value = items.value.map(item => item.id)
    }
  }

  function clearAll() {
    selectedIds.value = []
  }

  const selectedActionIndex = ref(0)

  const statusAction = reactive({
    loading: false,
    successCount: 0,
    errorCount: 0,
    total: 0,
    action: '',
  })

  function clearStatusAction(deep = false) {
    statusAction.loading = false
    statusAction.successCount = 0
    statusAction.errorCount = 0
    statusAction.total = selectedIds.value.length
    statusAction.action = actionList.value[selectedActionIndex.value].label

    if (deep) {
      statusAction.total = 0
      statusAction.action = ''
    }
  }
  async function runAction() {
    if (statusAction.loading) {
      return
    }

    clearStatusAction()

    const action = actionList.value[selectedActionIndex.value]

    const resButton = await instance.$swal({
      title: 'Вы уверены?',
      html: `Будет выполнено <strong>${action.label}</strong> для <strong>${selectedIds.value.length}</strong> выбранных элементов`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Да, выполнить!',
      cancelButtonText: 'Отмена',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    })

    statusAction.loading = true

    if (resButton.value) {
      if (!action.isMultiple) {
        // eslint-disable-next-line no-restricted-syntax
        for (const id of selectedIds.value) {
          const item = items.value.find(i => i.id === id)
          try {
            // eslint-disable-next-line no-await-in-loop
            await action.action(item)
            statusAction.successCount += 1
          } catch (e) {
            statusAction.errorCount += 1
            console.log(`Не удалось выполнить действие c ${id}`)
          }
        }
      } else {
        const selectedItems = selectedIds.value.map(id => items.value.find(item => item.id === id))

        try {
          await action.action(selectedItems)
          statusAction.successCount = selectedItems.length
        } catch (e) {
          statusAction.errorCount = selectedItems.length

          console.log(e)
        }
      }
    }
    statusAction.loading = false
    selectedIds.value = []
    await runAfterAction()
  }

  return {
    selectedIds,
    isSelectedAll,
    isIndeterminate,
    toggleAll,
    clearAll,

    actionList,
    selectedActionIndex,
    runAction,
    statusAction,
    clearStatusAction,
  }
}
