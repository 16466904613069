import axiosIns from '@/libs/axios'

export function productsToCsvForParser({
  ids,
}) {
  return axiosIns.post('/catalog/products/export/products-to-csv-for-parser', {
    ids,
  }, {
    responseType: 'blob',
  })
}

export default {
  productsToCsvForParser,
}
